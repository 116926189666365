import React from 'react'

// example change log https://developer.atlassian.com/cloud/confluence/changelog/

export function ChangeLog() {
    return (
        <div className={"bg-base-100"}>
            <section className={"pt-28 sm:pt-10 md:pt-16"}>
                <div className={"md:flex"}>
                    <div className={"mx-auto max-w-[80vw] md:max-w-[45vw]"}>
                        <h2 className={"text-2xl font-bold sm:text-3xl lg:text-4xl"}>Change log</h2>
                        <p className={"mt-12 text-lg text-base-600 sm:text-xl"}>
                            Today we released the first beta version of the Blue-Fox solutions toolkit. Going Forward
                            you will be able to view the recent changes here.
                        </p>
                        <h3 className={"mt-5 text-lg font-bold sm:text-xl lg:text-2xl"}>10 September 2024</h3>
                        <h4 className={"mt-2 text-lg font-bold text-base-600 sm:text-xl"}>
                            <span className={"bg-success text-success-content pl-1 pr-1 rounded"}>Added</span> First
                            beta release of the Blue-Fox Solutions toolkit.
                        </h4>
                        <p className={"mt-2 text-lg text-base-600 sm:text-xl"}>
                            You can now download a copy of the toolkit on the <a href={"http://blue-fox.co.za"}> home
                            page of this website</a>. The following features are now available to use in the demo
                            version:
                        </p>
                        <ul className={"mt-4 text-lg text-base-600 sm:text-xl"}>
                            <li>
                                <span className={"font-bold"}>Restful: </span>
                                a direct interface between your developers and the API’s you use
                            </li>
                            <li>
                                <span className={"font-bold"}>Faker: </span>
                                when your provider API isn’t ready, fake them to keep your team moving.
                            </li>
                            <li>
                                <span className={"font-bold"}>Open API editor: </span>
                                see what your API will look like to those you want to serve long before
                                you go live
                            </li>
                            <li>
                                <span className={"font-bold"}>Markdown editor: </span>
                                see how your documentation will look Realtime as you type.
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className={"pt-28 sm:pt-10 md:pt-16"}>
            </section>
        </div>
    );
}