import * as React from "react";
import "../output.css";
import TabBar from "../component/tabBar/TabBar";
import {Theme} from "react-daisyui";
import {Home} from "./Home";
import {Route, Routes} from "react-router-dom";
import {ChangeLog} from "./ChangeLog";

function App() {
    let routes = [{
        id:0,
        label: "Home",
        href: "/",
        element: <Home/>
    },{
        id:1,
        label: "Demo",
        href: "https://demo.blue-fox.co.za",
    },{
        id:2,
        label: "Change log",
        href: "/change-log",
        element: <ChangeLog/>
    }];

    return (
        <Theme className={"bg-base-100 text-base-content h-screen"}>
            <div className={"h-full"}>
                <TabBar href={""} tabLinks={routes}/>
                <Routes>
                    {routes.map(route =>
                        <Route path={route.href} element={route.element} ></Route>
                    )}
                </Routes>
            </div>
        </Theme>
    );
}


export default App;
